import React from 'react'
import Button from '@mui/material/Button'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import styled from 'styled-components'

import SvgElement from '../svg'
import TypographyElement from '../typography'
import { primary_green, white, secondary_white } from '../../colors'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { shouldOpenNewTab } from '../../utils/shouldOpenNewTab'
import { linkStylingDict } from './styles/main'
import { linkStylingDictSubsite } from './styles/subsite'

const getLinkStyling = (variant, isSubsite = false) => {
  const stylingDict = isSubsite ? linkStylingDictSubsite : linkStylingDict
  if (variant in stylingDict) {
    return stylingDict[variant]
  }
  return ''
}

const itemElementStyling = {
  'background-color': secondary_white,
  padding: '15px 40px',
  width: '340px',
  position: 'relative',
  display: 'block',
  'box-shadow':
    '0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946)',

  '> *': {
    display: 'inline-block',
    'vertical-align': 'middle',
  },
  '[data-testid="ArrowForwardIcon"]': {
    width: '10px',
    position: 'absolute',
    right: '40px',
  },
  '.beforeIcon': {
    'margin-right': '40px',
  },

  '@media screen and (max-width: 340px)': {
    'max-width': '100%',
    'svg.arrowRight': {
      display: 'none',
    },
  },
}

const WrapperElement = styled.div`
  ${itemElementStyling}
`

const LinkElement = styled.a`
  ${itemElementStyling}
  :hover,
    :focus {
    background-color: ${primary_green};
    color: ${white} !important;
    span {
      color: ${white} !important;
    }
    svg path {
      stroke: ${white};
      fill: ${white};
    }
  }
`

const LinkStylingWrapper = styled.span`
  display: ${(props) =>
    ['border', 'innerSkewed'].includes(props.variant)
      ? 'block'
      : 'inline-flex'};

  a {
    /* No default styling for base variant*/
    ${(props) =>
      !['base', 'baseArrow'].includes(props.variant)
        ? `
            ${itemElementStyling};
        `
        : ''}
    /* No hover effect for certain variants */
        /* ${(props) =>
      ![
        'border',
        'innerSkewed',
        'base',
        'baseArrow',
        'subsiteHeaderLink',
      ].includes(props.variant)
        ? `
            :hover,
            :focus {
                background-color: ${primary_green};
                color: ${white} !important;
                span {
                    color: ${white} !important;
                }
                svg path {
                    stroke: ${white};
                    fill: ${white};
                }
            }
        `
        : ``} */

        ${(props) => (props.styles ? props.styles : '')}
        ${(props) => (props.color ? `color: ${props.color}` : '')}
  }
`

export const InfoItem = ({
  title,
  text,
  icon,
  href,
  handleClick,
  withArrow,
  url,
}) => {
  const ElementWrapper =
    handleClick !== undefined || (href == undefined && !url)
      ? WrapperElement
      : LinkElement
  return (
    <ElementWrapper href={href || url} handleClick={handleClick}>
      {icon && <SvgElement variant={icon} className="beforeIcon" />}
      <TypographyElement variant="facilityText">
        {title || text}
      </TypographyElement>
      {withArrow && <ArrowForwardIcon />}
    </ElementWrapper>
  )
}

export const PrimaryButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="primary"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <TypographyElement component="span" variant="buttonText">
        {children}
      </TypographyElement>

      <ArrowForwardIcon className="arrowRight" />
    </Button>
  )
}

export const SecondaryButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="secondary"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <TypographyElement component="span" variant="secondaryButtonText">
        {children}
      </TypographyElement>
      <ArrowForwardIcon />
    </Button>
  )
}

export const BlankButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="blank"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <TypographyElement component="span" variant="blankButtonText">
        {children}
      </TypographyElement>
    </Button>
  )
}

export const EmptyButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="empty"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </Button>
  )
}

export const PlusButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="plus"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <TypographyElement component="span" variant="plusButtonText">
        {children}
      </TypographyElement>
    </Button>
  )
}

export const UploadButton = ({
  children,
  handleClick,
  disabled,
  component = 'label',
}) => {
  return (
    <Button
      variant="upload"
      onClick={handleClick}
      disabled={disabled}
      component={component}
    >
      <FileUploadOutlinedIcon />
      <TypographyElement component="span" variant="buttonText">
        {children}
      </TypographyElement>
    </Button>
  )
}

export const MoreButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="more"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <TypographyElement component="span" variant="moreButtonText">
        {children}
      </TypographyElement>
    </Button>
  )
}

export const SearchButton = ({
  children,
  handleClick,
  disabled,
  ...restProps
}) => {
  return (
    <Button
      variant="search"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <TypographyElement component="span" variant="moreButtonText">
        {children}
      </TypographyElement>
      <ArrowForwardIcon />
    </Button>
  )
}

export const IconButton = ({
  children,
  handleClick,
  disabled,
  icon,
  ...restProps
}) => {
  return (
    <Button
      variant="iconButton"
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      <SvgElement variant={icon} />
      <TypographyElement component="span" variant="iconButtonText">
        {children}
      </TypographyElement>
    </Button>
  )
}

// Filters
export const MoreFiltersButton = ({
  children,
  handleClick,
  expanded,
  disabled,
}) => {
  return (
    <Button variant="moreFilters" onClick={handleClick} disabled={disabled}>
      <TypographyElement component="span" variant="moreFiltersButtonText">
        {children} {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </TypographyElement>
    </Button>
  )
}

// Links
export const ExternalAwareLink = ({ children, href, ...props }) => {
  const [externalProps, setExternalProps] = React.useState({})
  const pathname = usePathname()
  React.useEffect(() => {
    const external = shouldOpenNewTab(href)
    setExternalProps(
      external
        ? {
            target: '_blank',
            rel: 'noopener',
          }
        : {}
    )
  }, [])

  let link = null
  if (!href || externalProps.target) {
    link = (
      <a {...externalProps} {...props} href={href}>
        {children}
      </a>
    )
  } else {
    link = (
      <Link {...externalProps} {...props} href={href}>
        {children}
      </Link>
    )
  }

  // const link = <Link {...externalProps} {...props} href={href}>
  //     {children}
  // </Link>

  const isSubsite = pathname.includes('/sportlocaties/intern/')
  const styles = getLinkStyling(props.variant, isSubsite)
  if (styles) {
    return (
      <LinkStylingWrapper
        variant={props.variant}
        styles={styles}
        isSubsite={isSubsite}
        className="linkWrapper"
        color={props.color}
      >
        {link}
      </LinkStylingWrapper>
    )
  }

  return link
}

export const PrimaryLink = ({ children, href }) => {
  return (
    <ExternalAwareLink variant="primary" href={href}>
      <TypographyElement component="span" variant="buttonText">
        {children}
      </TypographyElement>

      <ArrowForwardIcon className="arrowRight" />
    </ExternalAwareLink>
  )
}

export const WhiteLink = ({ children, href }) => {
  return (
    <ExternalAwareLink variant="white" href={href}>
      <TypographyElement component="span" variant="buttonText">
        {children}
      </TypographyElement>

      <ArrowForwardIcon className="arrowRight" />
    </ExternalAwareLink>
  )
}

export const ReturnLink = ({ children, href }) => {
  return (
    <ExternalAwareLink variant="return" href={href}>
      <ArrowBackIcon width={10} />
      <TypographyElement component="span" variant="buttonText">
        {children}
      </TypographyElement>
    </ExternalAwareLink>
  )
}

export const ArrowLink = (props) => {
  if (props.noLink) {
    return (
      <span>
        <div
          className="svgContainer"
          style={{
            position: 'relative',
            left: '28px',
            top: '25px',
          }}
        >
          <SvgElement variant="arrowUpperRight" />
        </div>
      </span>
    )
  }
  return (
    <ExternalAwareLink variant="arrow" {...props}>
      <div className="svgContainer">
        <SvgElement variant="arrowUpperRight" />
      </div>
    </ExternalAwareLink>
  )
}
export const BorderedLink = ({ children, ...restProps }) => {
  return (
    <ExternalAwareLink variant="border" {...restProps}>
      {children}
      <ArrowForwardIcon width={24} viewBox="4 0 16 16" />
    </ExternalAwareLink>
  )
}

export const BaseLink = ({ children, ...restProps }) => {
  return (
    <ExternalAwareLink variant="base" {...restProps}>
      {children}
    </ExternalAwareLink>
  )
}

export const SubsiteHeaderLink = ({ children, ...restProps }) => {
  return (
    <ExternalAwareLink variant="subsiteHeaderLink" {...restProps}>
      {children}
    </ExternalAwareLink>
  )
}

export const BaseArrowLink = ({ children, ...restProps }) => {
  return (
    <ExternalAwareLink variant="baseArrow" {...restProps}>
      <TypographyElement component="span" variant="secondaryButtonText">
        {children}
      </TypographyElement>
      <ArrowForwardIcon className="arrowRight" />
    </ExternalAwareLink>
  )
}

export const InnerSkewedLink = ({ children, ...restProps }) => {
  return (
    <ExternalAwareLink variant="innerSkewed" {...restProps}>
      {children}
    </ExternalAwareLink>
  )
}

const buttonDict = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  more: MoreButton,
  search: SearchButton,
  iconButton: IconButton,
  blank: BlankButton,
  empty: EmptyButton,
  plus: PlusButton,
  upload: UploadButton,
  arrow: ArrowLink,
  border: BorderedLink,
  primaryLink: PrimaryLink,
  whiteLink: WhiteLink,
  moreFilters: MoreFiltersButton,
  return: ReturnLink,
  base: BaseLink,
  subsiteHeaderLink: SubsiteHeaderLink,
  baseArrowLink: BaseArrowLink,
  innerSkewed: InnerSkewedLink,
}

const ButtonElement = ({ variant = 'primary', children, ...restProps }) => {
  if (!(variant in buttonDict)) {
    variant = 'primary'
  }
  const Element = buttonDict[variant]
  return <Element {...restProps}>{children}</Element>
}

export default ButtonElement
